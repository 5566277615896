<template>
  <div v-if="Object.keys(presentation).length === 0">

    <div class="theme_one_block">
      <div class="theme_one_header_block">
        <div class="theme_one_header_container">
          <div class="theme_one_header_label text-center w-100">
            Not found
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="theme_one_block">
      <div class="theme_one_header_block">
        <div class="theme_one_header_container">
          <div class="theme_one_header_label">
            {{ presentation.title }}
          </div>
        </div>
      </div>
      <div class="theme_one_main_block">
        <div class="theme_one_main_container">
          <div class="theme_one_content_container">
  <!--          <a class="theme_one_content_container_btn_back">-->
  <!--            <div class="theme_one_content_container_btn_text">Назад</div>-->
  <!--          </a>-->
            <div class="theme_one_plot">
              <div class="theme_one_prez_plot_theme_active">
                <div class="theme_one_plot_theme_active_label">Тема 1</div>
                <div class="theme_one_plot_theme_active_inactive_adv">Советы по изучению языка</div>
                <div class="theme_one_plot_theme_active_inactive_video">Особенности диалектов в ...</div>
                <div class="theme_one_plot_theme_active_selected_slide">Произношение отдельны...</div>
                <div class="theme_one_plot_theme_active_inactive_pencil">Упражнения</div>
              </div>
              <a href="#" class="theme_one_plot_theme">
                Тема 2
              </a>
              <a href="#" class="theme_one_plot_theme">
                Тема 3
              </a>
              <a href="#" class="theme_one_plot_theme">
                Тема 4
              </a>
              <a href="#" class="theme_one_plot_theme">
                Тестирование
              </a>
            </div>
          </div>
          <div class="theme_one_prez_desc">
            <div class="theme_one_prez_desc_container">
              <div class="theme_one_desc_text_container">
                <div class="theme_one_desc_text_container_label">{{ currentPage }}</div>
                <pdf
                    style="display: inline-block; width: 100%"
                    :page="currentPage"
                    :src="pdfPath"
                ></pdf>
              </div>
              <span>
              </span>
              <div class="theme_one_desc_img_block">
                <div class="theme_one_desc_img_container">
                  <div class="theme_one_desc_slide_page"
                       v-for="page in pageCount"
                       :style="getPageScreenshot(page)"
                       @click="goToPage(page)">
                    <i :class="{'active': page === currentPage}">{{ page }}</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="theme_one_prez_slider">
              <a class="theme_one_prez_slider_prev">Предыдущий слайд</a>
              <a class="theme_one_prez_slider_next">Следующий слайд</a>
            </div>
            <div class="theme_one_desc_btn">
              <a href="#" class="theme_one_desc_btn_back">Назад</a>
              <a href="#" class="theme_one_desc_btn_next">Далее</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'Presentation',
  data() {
    return {
      src: undefined,
      pdfPath: undefined,
      currentPage: 1,
      pageCount: undefined,
      presentation: {}
    }
  },
  components: {
    pdf
  },
  async mounted() {
    let id = this.$route.params.id;
    let apiRoot = window.API_ROOT;
    await this.$http.get(`${apiRoot}/api/presentations/${id}`)
      .then(res => {
        this.presentation = res.body
        this.pdfPath = this.presentation.path.replace('.pptx', '.pdf')
        this.pdfPath = this.pdfPath.replace('.ppt', '.pdf')
        this.src = pdf.createLoadingTask(this.pdfPath)
      })
      .catch(err => {

      })
      .finally(() => {

      })
    await this.src.promise.then(pdf => {
      this.pageCount = pdf.numPages;
    }).catch(err => {
      console.log(err)
    });
  },
  methods: {
    getPageScreenshot(page) {
      let pageText = `-${page - 1}.jpg`
      let screenUrl = this.pdfPath.replace('.pdf', pageText)
      return `background-image: url('${screenUrl}')`
    },
    goToPage(page) {
      this.currentPage = page
    }
  }
}

</script>

<style scoped>

</style>